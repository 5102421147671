/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
var filters = {};

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // init Isotope
          var $grid = $('.grid').isotope({
            itemSelector: '.item',
            percentPosition: true,
            layoutMode: 'masonry'
          });
          
          $('#filters').on( 'change', function( jQEvent ) {
            var $checkbox = $( jQEvent.target );

            manageCheckbox( $checkbox );

            var comboFilter = getComboFilter( filters );


            if( $('#backUrl').attr('href') != undefined){
              window.location = $('#backUrl').attr('href') + '/#filter=' + encodeURIComponent( comboFilter )
            }

            location.hash = '/filter=' + encodeURIComponent( comboFilter );

          });


          var isIsotopeInit = false;

          function onHashchange() {

            var hashFilter = getHashFilter();
            if ( !hashFilter && isIsotopeInit ) {
              return;
            }
            isIsotopeInit = true;
            // filter isotope
            $grid.isotope({ filter: hashFilter });
            
            //  !!! TODO set selected class on button
            if ( hashFilter ) {
              //$filters.find('.is-checked').removeClass('is-checked');
              var str = decodeURIComponent(hashFilter);
              var checks = str.split(', ');
              for( var ch in checks){
                $('#filters').find('[value="' + checks[ch] + '"]').attr( "checked",true);
              };
            }

          }

          $(window).on( 'hashchange', onHashchange );
          
          onHashchange();



          $('.tiled-gallery').each( function() {
            var $pic     = $(this),
                getItems = function() {
                    var items = [];
                    $pic.find('.tiled-gallery-item a img').each(function() {
                        var $href   = $(this).data('orig-file'),
                            $size   = $(this).data('orig-size').split(','),
                            $width  = $size[0],
                            $height = $size[1];

                        var item = {
                            src : $href,
                            w   : $width,
                            h   : $height
                        }

                        items.push(item);
                    });
                    return items;
                }

            var items = getItems();

            //console.log(items);


            var $pswp = $('.pswp')[0];


            $pic.on('click', 'a img', function(event) {
                event.preventDefault();

                var $index = $(this).index();
                var options = {
                    index: $index,
                    bgOpacity: 0.7,
                    showHideOpacity: true
                }

                // Initialize PhotoSwipe
                var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
                lightBox.init();
            });


        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


  function getHashFilter() {
    var hash = location.hash;
    // get filter=filterName
    var matches = location.hash.match( /filter=([^&]+)/i );
    var hashFilter = matches && matches[1];
    return hashFilter && decodeURIComponent( hashFilter );
  }


    function getComboFilter( filters ) {
      var i = 0;
      var comboFilters = [];
     // var message = [];

      for ( var prop in filters ) {
       // message.push( filters[ prop ].join(' ') );
        //console.log(filters);

        var filterGroup = filters[ prop ];
        // skip to next filter group if it doesn't have any values
        if ( !filterGroup.length ) {
          continue;
        }
        if ( i === 0 ) {
          // copy to new array
          comboFilters = filterGroup.slice(0);
        } else {
          var filterSelectors = [];
          // copy to fresh array
          var groupCombo = comboFilters.slice(0); // [ A, B ]
          // merge filter Groups
          for (var k=0, len3 = filterGroup.length; k < len3; k++) {
            for (var j=0, len2 = groupCombo.length; j < len2; j++) {
              filterSelectors.push( groupCombo[j] + filterGroup[k] ); // [ 1, 2 ]
            }

          }
          // apply filter selectors to combo filters for next group
          comboFilters = filterSelectors;
        }
        i++;
      }

      var comboFilter = comboFilters.join(', ');
      comboFilter = (comboFilter == '')? '*': comboFilter;
      return comboFilter;
    }

  function manageCheckbox( $checkbox ) {
    var checkbox = $checkbox[0];

    var group = $checkbox.parents('.option-set').attr('data-group');
    // create array for filter group, if not there yet
    var filterGroup = filters[ group ];
    if ( !filterGroup ) {
      filterGroup = filters[ group ] = [];
    }

    var isAll = $checkbox.hasClass('all');
    // reset filter group if the all box was checked
    if ( isAll ) {
      delete filters[ group ];
      if ( !checkbox.checked ) {
        checkbox.checked = 'checked';
      }
    }
    // index of
    var index = $.inArray( checkbox.value, filterGroup );

    if ( checkbox.checked ) {
      var selector = isAll ? 'input' : 'input.all';
      $checkbox.siblings( selector ).removeAttr('checked');


      if ( !isAll && index === -1 ) {
        // add filter to group
        filters[ group ].push( checkbox.value );
      }

    } else if ( !isAll ) {
      // remove filter from group
      filters[ group ].splice( index, 1 );
      // if unchecked the last box, check the all
      if ( !$checkbox.siblings('[checked]').length ) {
        $checkbox.siblings('input.all').attr('checked', 'checked');
      }
    }

  }




})(jQuery); // Fully reference jQuery after this point.
